import { FC } from "react";
import { ButtonGroupProps } from "./types";
import { styles } from "./styles";

const ButtonGroup: FC<ButtonGroupProps> = ({ children, fill }) => {
  return (
    <div
      css={[styles.wrapper, fill && styles.fill]}
      data-testid="kit-ButtonGroup"
    >
      {children}
    </div>
  );
};

export default ButtonGroup;
export * from "./types";
