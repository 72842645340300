import { css } from "@emotion/react";

export const styles = {
  wrapper: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    appearance: none;
    user-select: none;
    position: relative;
    white-space: nowrap;
  `,
  crumb: css`
    color: var(--color-tertiary);
    font-size: var(--font-size-12);
    line-height: var(--spacing-16);
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: var(--color-splash-primary);
    }
  `,
  currentCrumb: css`
    color: var(--color-primary);
    pointer-events: none;
  `,
  unclickableCrumb: css`
    pointer-events: none;
  `
};
