import { FC, PropsWithChildren, useRef } from "react";
import { css } from "@emotion/react";
import { PageStickyProps } from "./types";

const PageSticky: FC<PropsWithChildren<PageStickyProps>> = ({
  children,
  offsetByParent = true
}) => {
  const element = useRef<HTMLDivElement>(null);

  return (
    <>
      {/* Use a statically positioned element for the reference location so that the layout changes will update the offset correctly. */}
      <div ref={element} />
      <div
        className="kit-PageSticky"
        css={css`
          position: sticky;
          top: ${offsetByParent && element.current?.offsetTop
            ? element.current.offsetTop
            : "0"}px;
          left: 0;
          right: 0;
          z-index: 10;
        `}
      >
        {children}
      </div>
    </>
  );
};

export default PageSticky;
