import { FilterTypes } from "../handlers/filter";
import { OBJ_TYPE_TO_NAME } from "../../types/constants";
import { collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { v4 as uuid } from "uuid";
const PEER_SAVES_FIRESTORE_COLLECTION = "peerChanges";
/**
 * Tool for tracking data changes by other users with Firestore.
 */
export class PeerSaves {
    /**
     * Constructor.
     * @param sdk Instance of MadSDK.
     */
    constructor(sdk) {
        this.saveUnsubscribers = {};
        /**
         * Keep track of the last time we stopped listening to each resource so that we don't hear repeats.
         * This is done instead of just using the current time because the sdk will cache the data from the
         * first access and a save may have happened after that, while we weren't listening.
         */
        this.stoppedListeningTimestamps = {};
        /**
         * Sends information about a save to Firestore.
         * @param resourceId Unique identifier of the data.
         * @param collection Group to associate save with.
         * @param isNew Flag indicating the save is a new resource.
         */
        this.save = (resourceId, collection, isNew = false) => {
            if (!this.user)
                return;
            const userOrgId = this.user.primaryOrganizationId;
            const savedChanges = {
                resourceId,
                userId: this.user?.id,
                timestamp: Date.now(),
                instanceId: this.instanceId,
                isNew
            };
            setDoc(doc(this.sdk.madFire.firestore, PEER_SAVES_FIRESTORE_COLLECTION, userOrgId, collection, resourceId), savedChanges);
        };
        /**
         * Initialize Firestore snapshot session for saved made by other users to the resource specified.
         * @param resourceId The unique identifier for this data.
         * @param collection Group save is associated with.
         * @param onSave Callback that is executed when a peer saves data.
         */
        this.startListening = (resourceId, collection, onSave) => {
            if (!this.user)
                return;
            const userOrgId = this.user.primaryOrganizationId;
            this.stopListening(resourceId);
            // If this is the first time listening, use current time to define start of listening window.
            const timeSinceLastCheck = this.stoppedListeningTimestamps[resourceId] || Date.now();
            this.saveUnsubscribers[resourceId] = onSnapshot(doc(this.sdk.madFire.firestore, PEER_SAVES_FIRESTORE_COLLECTION, userOrgId, collection, resourceId), async (document) => {
                const changes = document.data();
                // Ignore saves by the same instance
                if (!changes ||
                    changes.instanceId === this.instanceId ||
                    changes.timestamp < timeSinceLastCheck) {
                    return;
                }
                const typeId = await this.sdk.cryptography.getTypeFromKey(changes.resourceId);
                onSave({
                    user: await this.sdk.users.find_once({
                        where: [{ field: "id", type: FilterTypes.EQ, value: changes.userId }]
                    }),
                    timestamp: new Date(changes.timestamp),
                    resourceId: changes.resourceId,
                    heardAs: "singular",
                    dataType: OBJ_TYPE_TO_NAME[typeId],
                    dataTypeId: typeId
                });
            });
        };
        /**
         * Initialize Firestore snapshot session for saved made by other users to any resources of a given type.
         * @param objType The ObjType of the resources to listen to.
         * @param onSave Callback that is executed when a peer saves data.
         */
        this.startListeningByCollection = (collectionId, onSave) => {
            if (!this.user)
                return;
            const userOrgId = this.user.primaryOrganizationId;
            this.stopListening(collectionId);
            this.saveUnsubscribers[collectionId] = onSnapshot(query(collection(this.sdk.madFire.firestore, PEER_SAVES_FIRESTORE_COLLECTION, userOrgId, collectionId), where("timestamp", ">", this.stoppedListeningTimestamps[collectionId] || Date.now())), async (snapshot) => {
                const documents = snapshot.docChanges();
                for (const document of documents) {
                    const changes = document.doc.data();
                    // Ignore saves by the same instance.
                    // Note: Firestore restrictions don't allow for this logic in the query.
                    if (changes.instanceId === this.instanceId) {
                        return;
                    }
                    const typeId = await this.sdk.cryptography.getTypeFromKey(changes.resourceId);
                    onSave({
                        user: await this.sdk.users.find_once({
                            where: [{ field: "id", type: FilterTypes.EQ, value: changes.userId }]
                        }),
                        timestamp: new Date(changes.timestamp),
                        resourceId: changes.resourceId,
                        heardAs: changes.isNew ? "new" : "list",
                        dataType: OBJ_TYPE_TO_NAME[typeId],
                        dataTypeId: typeId
                    });
                }
            });
        };
        /**
         * Unsubscribe from Firestore snapshot session for saves made by other users.
         * @param resourceIdOrObjType The resourceId or ObjType that was used to start listening.
         * @param onSave Callback that is executed when a peer saves data.
         */
        this.stopListening = (resourceIdOrObjType) => {
            const listenerKey = resourceIdOrObjType.toString();
            if (!this.isListening(listenerKey))
                return;
            this.saveUnsubscribers[listenerKey]();
            delete this.saveUnsubscribers[listenerKey];
            this.stoppedListeningTimestamps[listenerKey] = Date.now();
        };
        /**
         * Determines whether or not the given resourceId has an active listening session.
         * @param resourceIdOrObjType The resourceId or ObjType that was used to start listening.
         */
        this.isListening = (resourceIdOrObjType) => !!this.saveUnsubscribers[resourceIdOrObjType.toString()];
        this.sdk = sdk;
        this.user = sdk.getCurrentUser();
        this.instanceId = uuid();
        if (!this.user) {
            const authSubscription = sdk.authentication.onAuthStateChanged({
                next: (account) => {
                    if (account?.user) {
                        authSubscription.unsubscribe();
                        this.user = sdk.getCurrentUser();
                    }
                }
            });
        }
    }
}
