import { FC, Fragment, useCallback, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useResizeObserver } from "../../../hooks";
import Breadcrumbs from "../../Breadcrumbs";
import { PageHeaderProps } from "./types";
import { styles } from "./styles";
import { Page, Truncated } from "../..";

const PageHeader: FC<PageHeaderProps> = ({
  title,
  children,
  subhead,
  breadcrumbsProps,
  bordered,
  flush,
  noWrap,
  notSticky = false
}) => {
  const rightContentRef = useRef<HTMLDivElement>(null);

  const [leftContentMaxWidth, setLeftContentMaxWidth] = useState<string>("");

  const handleSetLeftContentMaxWidth = useCallback(() => {
    if (!rightContentRef.current) {
      setLeftContentMaxWidth("100%");
      return;
    }
    setLeftContentMaxWidth(
      `calc(100% - ${(rightContentRef.current?.offsetWidth || 0) + 32}px)`
    );
  }, [rightContentRef.current?.offsetWidth, noWrap]);

  useResizeObserver(rightContentRef, handleSetLeftContentMaxWidth);

  const WrapperComponent = notSticky ? Fragment : Page.Sticky;

  return (
    <WrapperComponent>
      <div
        className="kit-PageHeader"
        css={[
          styles.wrapper,
          noWrap && styles.noWrap,
          !flush && styles.shadowed,
          bordered && styles.bordered
        ]}
      >
        <div
          css={[
            styles.leftContent,
            css`
              ${noWrap
                ? `max-width: ${leftContentMaxWidth}`
                : `@media (min-width: 600px) {
                max-width: ${leftContentMaxWidth};
              }`}
            `
          ]}
        >
          <div
            css={[
              styles.title,
              subhead && styles.subTitle,
              subhead === "small" && styles.smallSubhead
            ]}
          >
            {typeof title === "string" ? (
              <Truncated text={title as string} />
            ) : (
              title
            )}
          </div>
          {subhead && breadcrumbsProps && <Breadcrumbs {...breadcrumbsProps} />}
        </div>
        <div ref={rightContentRef} css={styles.rightContent}>
          {children}
        </div>
      </div>
    </WrapperComponent>
  );
};

export default PageHeader;
export * from "./types";
