import { useEffect, useState } from "react";
import { PeerChangesHandler } from "@madhive/mad-sdk/modules/peerSaves/PeerChangesHandler";
import { madSDK } from "../../../sdk";
export const peerChangesHandler = new PeerChangesHandler(madSDK);
/**
 * Hook that provides changes as a React state variable.
 * (Mad-hooks will not track changes if this hook is never used.)
 * @param options Object containing Peer Changes Options.
 * @returns PeerChanges
 */
export const usePeerChanges = (options) => {
    const [changes, setChanges] = useState({});
    useEffect(() => {
        peerChangesHandler.initialize(setChanges, options);
        return () => {
            peerChangesHandler.close();
        };
    }, []);
    return changes;
};
