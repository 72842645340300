import { spinner, path, Wrapper } from "./style";
import { SpinnerProps, SpinnerSize } from "./types";

const SpinnerSizeToPx = {
  [SpinnerSize.TINY]: 12,
  [SpinnerSize.SMALL]: 16,
  [SpinnerSize.MEDIUM]: 32
};

const Spinner = ({
  size = SpinnerSize.MEDIUM,
  color = "var(--color-splash-primary)",
  "data-testid": dataTestId
}: SpinnerProps) => {
  return (
    <Wrapper data-testid={dataTestId} width={SpinnerSizeToPx[size]}>
      <svg className={spinner} viewBox="16 16 32 32">
        <circle
          className={path}
          cx="32"
          cy="32"
          r="14"
          fill="none"
          stroke={color}
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </svg>
    </Wrapper>
  );
};

export { Spinner };
