import { BehaviorSubject } from "rxjs";
/**
 * Handles tracking the current changes and options.
 * Note: This is a temporary solution that shouldn't be needed if we can adjust the observable/promise
 * handlers to include this logic.
 */
export class PeerChangesHandler {
    constructor(sdk) {
        this.initialized = false;
        this.save = (resourceId, collection, isNew) => {
            resourceId && this.sdk.peerSaves.save(resourceId, collection, isNew);
        };
        this.startListeningSingular = async (resourceId, collection, callback) => {
            if (!this.initialized || !resourceId)
                return undefined;
            return this.startListening(resourceId, collection, false, callback);
        };
        this.startListeningList = async (resourceId, collection, callback) => {
            if (!this.initialized || !resourceId)
                return undefined;
            return this.startListening(resourceId, collection, true, callback);
        };
        this.startListening = async (resourceId, collection, isList, callback) => {
            const objType = await this.sdk.cryptography.getTypeFromKey(resourceId);
            const exclude = isList ? this.options.dataTypes?.list : this.options.dataTypes?.singular;
            if (exclude && exclude.includes(objType)) {
                return undefined;
            }
            if (!this.sdk.peerSaves.isListening(resourceId)) {
                const onHearSave = async (saved) => {
                    if (!saved?.resourceId)
                        return;
                    if (callback(saved) !== false) {
                        this.observable.next({ saved });
                    }
                };
                isList
                    ? this.sdk.peerSaves.startListeningByCollection(collection, onHearSave)
                    : this.sdk.peerSaves.startListening(resourceId, collection, onHearSave);
                return this.observable.subscribe({
                    next: (peerChanges) => {
                        this.setChanges(peerChanges);
                    }
                });
            }
            return undefined;
        };
        this.stopListening = (resourceId) => {
            this.sdk.peerSaves.stopListening(resourceId);
        };
        this.sdk = sdk;
        this.observable = new BehaviorSubject({});
    }
    initialize(setChanges, options) {
        this.setChanges = setChanges;
        this.initialized = true;
        this.options = options || {};
    }
    close() {
        this.observable.complete();
    }
    get active() {
        return this.observable.observed;
    }
}
