import { css } from "@emotion/react";

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-16);
  `,
  fill: css`
    width: 100%;
  `
};
